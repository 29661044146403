<template>
  <v-row class="custom-content-container pt-0">
    <v-col md="4">
      <v-hover v-slot="{ hover }">
        <div class="p-10">
          <v-progress-circular
            indeterminate
            :size="100"
            :width="10"
            color="cyan"
            v-if="primaryLoader"
          ></v-progress-circular>
          <v-img
            v-else
            max-height="140px"
            max-width="140px"
            :lazy-src="$defaultProfileImage"
            :src="getProfileImage"
            class="custom-grey-border margin-auto custom-border-radius-50"
          >
          </v-img>
          <template v-if="getPermission('engineer:update')">
            <div v-if="hover" class="engineer-image-action">
              <v-btn
                class="mx-4 custom-bold-button"
                outlined
                small
                :disabled="primaryLoader"
                :loading="primaryLoader"
                v-on:click="selectImage"
                color="cyan"
              >
                <template v-if="getProfileImage">Change Image</template>
                <template v-else>Upload Image</template>
              </v-btn>
              <div class="d-none">
                <v-file-input
                  v-model.trim="rawImage"
                  ref="uploadImage"
                  :rules="imageRules"
                  accept="image/png, image/jpeg, image/bmp"
                ></v-file-input>
              </div>
            </div>
          </template>
        </div>
      </v-hover>
    </v-col>
    <v-col cols="8" class="pb-0 gray-background pl-4">
      <div class="p-10">
        <Barcode :barcode="getBarcode"></Barcode>
        <table class="width-100">
          <tr>
            <td class="pb-2 width-200px font-size-18 text-capitalize">
              Full name
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <span v-if="getFullName">{{ getFullName }}</span>
              <em class="text--secondary" v-else>no full name</em>
            </td>
          </tr>
          <tr>
            <td class="pb-2 width-200px font-size-18 text-capitalize">
              Display name
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <span v-if="getDisplayName">{{ getDisplayName }}</span>
              <em class="text--secondary" v-else>no display name</em>
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Designation</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <span v-if="getDesignation">{{ getDesignation }}</span>
              <em class="text--secondary" v-else>no designation</em>
            </td>
          </tr>
          <!--  <tr>
            <td class="pb-2 font-size-18 text-capitalize">Role</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              {{ getRole }}
            </td>
          </tr> -->
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Skills</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <span v-if="getSkills">{{ getSkills }}</span>
              <em class="text--secondary" v-else>no skills</em>
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Email</td>
            <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
              <span v-if="getEmail">{{ getEmail }}</span>
              <em class="text--secondary" v-else>no email</em>
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Phone number</td>
            <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
              <span v-if="getMobile">{{ getMobile }}</span>
              <em class="text--secondary" v-else>no phone number</em>
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Login</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              {{ getAllowLogin }}
            </td>
          </tr>
          <!-- <tr>
            <td class="pb-2 font-size-18 text-capitalize">Leave</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              {{ getAllowLeave }}
            </td>
          </tr> -->
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { PATCH } from "@/core/services/store/request.module";

export default {
  name: "personal-info",
  mixins: [CommonMixin, FileManagerMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      rawImage: null,
      profileImage: null,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    Barcode,
  },
  watch: {
    rawImage(param) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.uploadAttachment(param).then((response) => {
          _this.profileImage = _this.lodash.head(response);
          if (_this.lodash.isEmpty(_this.profileImage) === false) {
            _this.$store
              .dispatch(PATCH, {
                url: "engineer/" + _this.engineer.id,
                data: {
                  profile_logo: _this.profileImage.id,
                  role: _this.engineer.role.id,
                },
              })
              .catch((error) => {
                _this.logError(error);
              });
          }
        });
      }
    },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
  },
  computed: {
    getProfileImage() {
      const _this = this;
      let result = null;
      if (_this.lodash.isEmpty(_this.profileImage) === false) {
        return _this.profileImage.file.url;
      }
      if (
        _this.lodash.isEmpty(_this.engineer) === false &&
        _this.lodash.isEmpty(_this.engineer.profile_logo) === false &&
        _this.lodash.isEmpty(_this.engineer.profile_logo.file) === false
      ) {
        result = _this.engineer.profile_logo.file.url;
      }
      return result;
    },
    getBarcode() {
      return this.lodash.isEmpty(this.engineer) === false
        ? this.engineer.barcode
        : "";
    },
    getSkills() {
      return this.lodash.isEmpty(this.engineer) === false
        ? this.engineer.skills
          ? this.engineer.skills.join(", ")
          : ""
        : "";
    },
    getRole() {
      return this.lodash.isEmpty(this.engineer.role) === false
        ? this.engineer.role.name
        : "";
    },
    getDesignation() {
      const _this = this;
      let result = null;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        if (_this.lodash.isEmpty(_this.engineer.designation) === false) {
          result = _this.engineer.designation.text;
        }
      }
      return result;
    },
    getEmail() {
      return this.lodash.isEmpty(this.engineer) === false
        ? this.engineer.primary_email
        : "";
    },
    getMobile() {
      return this.lodash.isEmpty(this.engineer) === false
        ? this.engineer.primary_phone
        : "";
    },
    getFullName() {
      return this.lodash.isEmpty(this.engineer) === false
        ? this.engineer.full_name
        : "";
    },
    getDisplayName() {
      return this.lodash.isEmpty(this.engineer) === false
        ? this.engineer.display_name
        : "";
    },
    getAllowLogin() {
      let status = "No";
      if (this.lodash.isEmpty(this.engineer) === false) {
        if (this.engineer.can_login == 1) {
          status = "Yes";
        }
      }
      return status;
    },
    getAllowLeave() {
      let status = "No";
      if (this.lodash.isEmpty(this.engineer) === false) {
        if (this.engineer.allow_leave == 1) {
          status = "Yes";
        }
      }
      return status;
    },
  },
};
</script>
